<template>
  <div class="h5">Assign Sales Agent</div>
  <div
    v-if="!isLoading && $can('view', 'assign_freshsales_agents')"
    class="list-group"
  >
    <div
      v-for="item in combinedData.data"
      :key="item.id"
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      <div class="d-flex align-items-center">
        <div
          v-if="
            $can('create', 'assign_freshsales_agents') ||
            $can('update', 'assign_freshsales_agents')
          "
          class="form-check me-3"
        >
          <input
            :id="`checkbox-${item.id}`"
            class="form-check-input"
            type="checkbox"
            :value="item.id"
            :checked="selectedIds.includes(item.id.toString())"
            @change="handleCheckboxChange(item.id, $event.target.checked)"
          />
        </div>
        <div>
          <h6 class="mb-1">{{ item.name }}</h6>
          <small class="text-muted">{{ item.email }}</small>
        </div>
      </div>
      <span class="badge bg-primary rounded-pill">
        <i
          :class="
            item.status == 'active'
              ? 'bi bi-check-lg text-success'
              : 'bi bi-x-lg text-danger'
          "
        ></i>
        {{ item.status }}
      </span>
    </div>
  </div>
  <div v-if="hasChanges" class="mt-3 d-flex justify-content-end gap-3">
    <button
      v-if="selectedIds.length > 0"
      class="btn btn-success"
      @click="updateChanges"
    >
      Update
    </button>
    <button class="btn btn-secondary ml-2" @click="cancelChanges">
      Cancel
    </button>
  </div>
</template>

<script>
import ApiAgents from "@/services/apiAgents";
import api from "@/services/api";

export default {
  name: "ListTable",
  props: {
    filter: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      combinedData: { data: [] },
      selectedIds: [],
      initialSelectedIds: [],
      isLoading: true,
      hasChanges: false,
    };
  },
  mounted() {
    this.fetchData();
    this.fetchSelectedIds();
  },
  methods: {
    async fetchData() {
      try {
        const [salesAgentsList, salesTeamLeadList] = await Promise.all([
          api.getSalesAgents(),
          api.getSalesTL(),
        ]);
        const salesAgents = salesAgentsList.data.data;
        const salesTeamLeads = salesTeamLeadList.data.data;
        this.combinedData = {
          data: [...salesAgents, ...salesTeamLeads],
        };
      } catch (error) {
        console.error("Error fetching report data:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchSelectedIds() {
      try {
        const response = await ApiAgents.get();
        if (response.data.success && response.data.agents) {
          this.selectedIds = response.data.agents;
          this.initialSelectedIds = [...this.selectedIds];
        } else {
          console.error("Invalid response format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching selected IDs:", error);
      }
    },
    handleCheckboxChange(rowId, isChecked) {
      try {
        if (isChecked) {
          this.selectedIds.push(rowId.toString());
        } else {
          this.selectedIds = this.selectedIds.filter(
            (id) => id !== rowId.toString()
          );
        }
        this.hasChanges = true;
      } catch (error) {
        console.error("Error updating agent status:", error);
      }
    },
    async updateChanges() {
      try {
        await ApiAgents.update(this.selectedIds);
        this.hasChanges = false;
      } catch (error) {
        console.error("Error updating changes:", error);
      }
    },
    cancelChanges() {
      this.selectedIds = [...this.initialSelectedIds];
      this.hasChanges = false;
    },
  },
};
</script>
