<template>
  <div
    id="sidenav-collapse-main"
    class="collapse navbar-collapse w-auto h-auto h-100"
  >
    <ul class="navbar-nav">
      <template v-if="menuItems.length > 0">
        <li v-for="(menuItem, key) in menuItems" :key="key" class="nav-item">
          <template v-if="menuItem.subMenuItems">
            <sidenav-collapse
              v-if="showMenuItem(menuItem)"
              collapse-ref="pagesExamples"
              :nav-text="menuItem.navText"
              :class="menuItem.class"
            >
              <template #icon>
                <i class="text-sm" :class="menuItem.iconClass"></i>
              </template>
              <template #list>
                <ul class="nav ms-4">
                  <!-- nav links -->
                  <template
                    v-for="(
                      subMenuItem, subMenuItemKey
                    ) in menuItem.subMenuItems"
                    :key="subMenuItemKey"
                  >
                    <template v-if="showItem(subMenuItem)">
                      <sidenav-item
                        v-if="subMenuItem.roleId"
                        :to="subMenuItem.to"
                        :mini-icon="subMenuItem.miniIcon"
                        :text="subMenuItem.text"
                        :text-class="menuItem.textClass"
                        @click="
                          $store.commit(
                            'auth/setCurrentRole',
                            subMenuItem.roleId
                          )
                        "
                      />
                      <sidenav-item
                        v-else
                        :to="subMenuItem.to"
                        :mini-icon="subMenuItem.miniIcon"
                        :text="subMenuItem.text"
                        :text-class="menuItem.textClass"
                      />
                    </template>
                  </template>
                </ul>
              </template>
            </sidenav-collapse>
          </template>
          <template v-else>
            <sidenav
              v-if="showItem(menuItem)"
              :text-class="menuItem.textClass"
              :nav-text="menuItem.navText"
              :to="menuItem.to"
            >
              <template #icon>
                <i class="text-sm" :class="menuItem.iconClass"></i>
              </template>
            </sidenav>
          </template>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import Sidenav from "./Sidenav.vue";
import SidenavItem from "./SidenavItem.vue";
import SidenavCollapse from "./SidenavCollapse.vue";
import ability from "@/services/ability";
import { mapGetters } from "vuex";

export default {
  name: "SidenavList",
  components: {
    Sidenav,
    SidenavItem,
    SidenavCollapse,
  },
  data() {
    return {
      menuItems: [],
    };
  },
  computed: {
    ...mapGetters(["findDashboard"]),
  },
  mounted() {
    this.menuItems = this.getMenuItems();
    this.setMenuItemsGuard();
  },
  methods: {
    getMenuItems() {
      const defaultIconClass =
        this.$store.state.sidebarType === "bg-default" ||
        this.$store.state.darkMode
          ? "text-white"
          : "text-dark";
      let menuItems = [
        {
          navText: "Dashboard",
          class: this.getRoute() === "dashboard" ? "active" : "",
          iconClass: "ni ni-single-02 " + defaultIconClass,
          subMenuItems: this.getDashboardSubMenuItemsBasedOnRole(),
        },
        {
          navText: "Clients",
          auth: true,
          class: this.getRoute() === "clients" ? "active" : "",
          iconClass: "ni ni-single-02 " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Clients List" },
              miniIcon: "C",
              text: "Clients List",
            },
            {
              to: { name: "Clients Plans List" },
              miniIcon: "P",
              text: "Plans",
            },
            {
              to: { name: "Bag Deposits" },
              miniIcon: "B",
              text: "Bag Deposits",
            },
          ],
        },
        {
          navText: "Invoices & Orders",
          auth: true,
          class:
            this.getRoute() === "invoices" || this.getRoute() === "orders"
              ? "active"
              : "",
          iconClass: "ni ni-single-02 " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Invoices List" },
              miniIcon: "I",
              text: "Invoices",
            },
            {
              to: { name: "Order List" },
              miniIcon: "o",
              text: "Orders",
            },
          ],
        },
        {
          navText: "Nutritionist Queue",
          textClass: "mt-1",
          auth: true,
          to: { name: "Nutritionist Queue" },
          class: this.getRoute() === "nutritionist-queue" ? "active" : "",
          iconClass: "fa fa-layer-group " + defaultIconClass,
        },
        {
          navText: "Automatic Template",
          textClass: "mt-1",
          auth: true,
          to: { name: "Automatic Meal Template" },
          class: this.getRoute() === "automatic" ? "active" : "",
          iconClass: "fa fa-utensils " + defaultIconClass,
        },
        {
          navText: "Users",
          auth: true,
          class: this.getRoute() === "users" ? "active" : "",
          iconClass: "ni ni-single-02 " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Users List" },
              miniIcon: "U",
              text: "Users List",
            },
            {
              to: { name: "User Roles" },
              miniIcon: "R",
              text: "User Roles",
            },
            {
              to: { name: "New User" },
              miniIcon: "N",
              text: "New User",
            },
          ],
        },
        {
          navText: "Config",
          auth: true,
          class: this.getRoute() === "config" ? "active" : "",
          iconClass: "ni ni-settings opacity-10 " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Addons" },
              miniIcon: "A",
              text: "Addons",
            },
            {
              to: { name: "Allergens" },
              miniIcon: "A",
              text: "Allergens",
            },

            {
              to: { name: "Promo Codes" },
              miniIcon: "P",
              text: "Promo Codes",
            },
            {
              to: { name: "Food Groups" },
              miniIcon: "F",
              text: "Food Groups",
            },
            {
              to: { name: "Ingredients" },
              miniIcon: "I",
              text: "Ingredients",
            },
            {
              to: { name: "Freshsales Agent" },
              miniIcon: "F",
              text: "Freshsales Agent",
            },
            {
              to: { name: "Meal Templates" },
              miniIcon: "M",
              text: "Meal Templates",
            },
            {
              to: { name: "Products" },
              miniIcon: "P",
              text: "Products",
            },
            {
              to: { name: "Recipes" },
              miniIcon: "R",
              text: "Recipes",
            },
          ],
        },
        {
          navText: "Logistics",
          auth: true,
          class: this.getRoute() === "logistics" ? "active" : "",
          iconClass: "ni ni-collection " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Bag Movements" },
              miniIcon: "B",
              text: "Bag Movements",
            },
            {
              to: { name: "Bag Movement Check-in" },
              miniIcon: "I",
              text: "Bag checkin",
            },
            {
              to: { name: "Bag Movement Check-out" },
              miniIcon: "O",
              text: "Bag check-out",
            },
            {
              to: { name: "Change Bag Status" },
              miniIcon: "C",
              text: "Change Bag Status",
            },
            {
              to: { name: "Deliveries" },
              miniIcon: "D",
              text: "Deliveries",
            },
          ],
        },
        {
          navText: "Reports",
          auth: true,
          guard: {
            action: "view",
            subject: "reports",
          },
          class: this.getRoute() === "reports" ? "active" : "",
          iconClass: "ni ni-collection " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "Revenue Report" },
              miniIcon: "R",
              text: "Revenue",
            },
            {
              to: { name: "Future Revenue Report" },
              miniIcon: "FR",
              text: "Future Revenue",
            },
            {
              to: { name: "Collections Report" },
              miniIcon: "C",
              text: "Collections",
            },
            {
              to: { name: "Bag Deposit Refund Report" },
              miniIcon: "C",
              text: "Bag Deposit Refund",
            },
          ],
        },
        {
          navText: "Operations",
          auth: true,
          guard: {
            action: "view",
            subject: "reports",
          },
          class: this.getRoute() === "operations" ? "active" : "",
          iconClass: "ni ni-collection " + defaultIconClass,
          subMenuItems: [
            {
              to: { name: "SKU" },
              miniIcon: "S",
              text: "SKU",
            },
            {
              to: { name: "TDO" },
              miniIcon: "T",
              text: "TDO",
            },
            {
              to: { name: "Packing List" },
              miniIcon: "P",
              text: "Packing List",
            },
            {
              to: { name: "Delivery Schedule Export" },
              miniIcon: "D",
              text: "Delivery Schedule Export",
            },
            {
              to: { name: "Delivery Label" },
              miniIcon: "D",
              text: "Delivery Label",
            },
          ],
        },
      ];
      return menuItems;
    },
    setMenuItemsGuard() {
      for (let i in this.menuItems) {
        if (this.menuItems[i].auth) {
          if (this.menuItems[i].subMenuItems) {
            for (let j in this.menuItems[i].subMenuItems) {
              let route = this.$router.resolve({
                name: this.menuItems[i].subMenuItems[j].to.name,
              });
              if (route.meta && route.meta.guard) {
                this.menuItems[i].subMenuItems[j].guard = route.meta.guard;
              }
            }
          } else {
            let route = this.$router.resolve({
              name: this.menuItems[i].to.name,
            });
            if (route.meta && route.meta.guard) {
              this.menuItems[i].guard = route.meta.guard;
            }
          }
        }
      }
    },
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    },
    getDashboardSubMenuItemsBasedOnRole() {
      let subMenuItems = [];
      const uniqueRoutes = new Set(); // To track unique routes

      if (!this.$store.state.auth.user.roles) {
        return subMenuItems;
      }

      const roles = this.$store.state.auth.user.roles.data;
      roles.forEach((role) => {
        const dashboardRoute = this.findDashboard(role.id);
        if (dashboardRoute && !uniqueRoutes.has(dashboardRoute.name)) {
          uniqueRoutes.add(dashboardRoute.name);

          subMenuItems.push({
            to: dashboardRoute,
            miniIcon: role.name.charAt(0),
            text: role.name,
            roleId: role.id,
          });
        }
      });

      return subMenuItems;
    },
    showMenuItem(menuItem) {
      if (menuItem.auth) {
        for (let i in menuItem.subMenuItems) {
          if (
            menuItem.subMenuItems[i].guard &&
            menuItem.subMenuItems[i].guard.action &&
            ability.can(
              menuItem.subMenuItems[i].guard.action,
              menuItem.subMenuItems[i].guard.subject
            )
          ) {
            return true;
          }
        }
        return false;
      }
      return true;
    },
    showItem(item) {
      return !item.guard || ability.can(item.guard.action, item.guard.subject);
    },
  },
};
</script>
