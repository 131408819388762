import { httpApi } from "@/services/httpApi";

class ApiAgents {
  async get() {
    return await httpApi.get("beetle/v1/settings/freshsales-agents");
  }
  async update(data) {
    return await httpApi.post("beetle/v1/settings/freshsales-agents", {
      user_ids: data,
    });
  }
}

export default new ApiAgents();
